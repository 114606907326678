<template>
  <div class="home-page d-flex min-vh-100">
    <PreLoading />
  </div>
</template>

<script>
export default {
  name: "Logout",
  beforeMount() {
    this.performLogout();
    // 保持している宿ID初期化
    this.$store.commit('setUid',"");
    console.log("id reset");
    console.log(this.$store.state.uniqueId);
  },
  methods: {
    goLogin: function () {
      this.$router.push("/home");
    },
    performLogout: function () {
      console.log(this.$keycloak.$data);
      if (this.$keycloak.authenticated) {
        if (this.$keycloak.logoutFn) {
          this.$keycloak.logoutFn();
        }
      }
      this.goLogin();
    },
  },
};
</script>

